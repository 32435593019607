<template>
  <ul v-show="!isLoading" class="nav__account">
    <template v-if="isAuthenticated">
      <li><base-link class="nav__link" to="/account">Account</base-link></li>
    </template>
    <template v-else>
      <li>
        <button class="nav__link" @click.prevent="login()">Login</button>
      </li>
      <li>
        <base-link class="nav__link is-signup" to="/plans/#plans">Sign up</base-link>
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import BaseLink from '~/components/global/ui/BaseLink.vue';

import { computed, useAuth } from '#imports';

const { auth } = useAuth();

const isAuthenticated = computed(() => auth.isAuthenticated.value);

const isLoading = computed(() => auth.isLoading.value);

const login = () => {
  auth.loginWithRedirect({
    appState: { target: '/account' },
  });
};
</script>
